.Eventu{



    
    background: linear-gradient(60deg, #000000 0%, #585c61 50% );
    background-size: 400% 400%;
    animation: gradient 10s ease infinite;

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
}