
.body{

    background-color: black;
}

.Team{
    background-color: #4158D0;
    background-image: linear-gradient(43deg, #1f2022 0%, #313739 46%, #FFCC70 100%);

}    

.Home{
    background: black;
}





