.card {
  transform-style: preserve-3d;
  position: relative;
  padding: 25px;
  width: 350px;
  height: 610px;
  border-radius: 20px;
  box-shadow: rgba(36, 204, 255, 0.35) 0px 5px 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  transition: all 0.3s ease;
  overflow: hidden;
  box-shadow: 0 0 20px rgb(65, 255, 12);
  @media only screen and (max-width: 768px) {
    width: 100%; /* Make the card full width on smaller screens */
    height: auto; /* Allow the height to adjust based on content */
    margin-top: 20px;
    gap: 20px;
    
    
   } /* Adjust margin for spacing on smaller screens */

 
}

.card:hover .sneaaker-img {
  transform: scale(1.1) rotateX(5deg); 
  transition: transform 0.3s ease;
  @media only screen and (max-width: 768px) {
    transform: scale(1.05) rotateX(2deg);}
    
}

.card:hover .title,
.card:hover .detail,
.card:hover .sizes-box,
.card:hover .purchase {
  transform: translateZ(25px);
  transition: transform 0.3s ease;
}

.sneaaker-img {
  position: relative;
  width: fit-content;
  transition: all 0.5s ease;
  border-radius: 15px;
}

.title {
  font-size: 2rem;
  font-weight: 100;
  color: black;
  font-family: cursive;
}

.detail {
  font-size: 1.2rem;
  font-weight: 100;
  color: black;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
}

.card p {
  width: 100%;
  color: rgb(0, 0, 0);
}

.sizes-box {
  width: 100%;
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
  margin-top: 2px;
}

.sizes-box li {
  list-style: none;
  cursor: pointer;
  font-weight: bold;
  border-radius: 30px;
  padding: 0.5rem 1.5rem;
  background-color: #f8fdfd;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}



.button-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3px;
}

.purchase {
  cursor: pointer;
  width: 100%;
  border-radius: 30px;
  padding: 0.8rem 1.5rem;
  font-weight: bold;
  border: none;
  font-size: 1rem;
  letter-spacing: 1px;
  background-color: #b3f5f1;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.purchase:hover {
  background-color: rgb(32, 229, 213);
}

@media only screen and (max-width: 40rem) {
  .card {
    margin-top: 20px;
  }
}

