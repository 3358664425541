.event-details-right-div{
  overflow-y:scroll; 
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}
.eventImg {
    max-width: 90vw;
    /* position: sticky; */
    width: 300px;
    border-radius: 50px;
}
.event-details-right-div ::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

/* border Animation */
.box {
  display: grid;
  place-content: center;
  color: white;
  text-shadow: 0 1px 0 #000;
  
  --border-angle: 0turn;
  --main-bg: conic-gradient(
      from var(--border-angle),
      #213,
      #112 5%,
      #112 60%,
      #213 95%
    );
  
  border: solid 5px transparent;
  border-radius: 2em;
  --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #08f, #f03 99%, transparent);
  
  background: 
    var(--main-bg) padding-box,
    var(--gradient-border) border-box, 
    var(--main-bg) border-box;
  
  background-position: center center;

  animation: bg-spin 3s linear infinite;
  
  &:hover {
    animation-play-state: paused;
  }
}

@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}
@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}
