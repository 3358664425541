.team {
    flex-wrap: wrap;
    justify-content: center;
}

.team_card {
    overflow: hidden;
    transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
    border: 2px solid #ffd38c;
}

.team_card:hover {
    background-color: blue;
    scale: 1.1;
}

.team_name {
    display: none;
    opacity: 0;
    transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.8s;
}

.team_card:hover .team_name {
    display: flex;
    opacity: 1;
}

.team_card:hover .team_box {
    height: 40%;
}

.team_box {
    height: 30%;
    transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
}

@media screen and (max-width: 768px) {
    .team {
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: auto;
        justify-content: start;
        padding-left: 10px;
    }

    .team::-webkit-scrollbar{
        display: none;
    }

    .team_card {
        overflow: hidden;
        transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
        border: 2px solid #ffd38c;
    }

    .team_name {
        display: flex;
        opacity: 1;
    }

    .team_box {
        height: 40%;
    }
}
