.sponserImg{
    width: 250px;
    object-fit: cover;
    height: 250px;
}
@media(width<=600px){
    .sponserImg{
        width: 120px;
        height: 120px;
    }
}


.Sponsor{
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 10s ease infinite;


@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

}