
.video-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
.Gallery{
  background: black;
  color: gold;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
font-size: 20px;
margin-bottom: 0;
}

.name{
  font-weight: bold;
  text-shadow: rgba(0, 0, 0.5, 0.5) 1.95px 1.95px 2.6px;
}
.button1{
    background-color:#cc5f0b;
    border-radius: 20px;
    border: 5px;
    color: white;
    padding: 7px 20px;
    margin-top: 15px;
}
.Nichewala {
  color: white;
  margin-bottom: 20px;
font-size: 20px;
font-weight: bold;
  text-shadow: rgba(0, 0, 0.5, 0.5) 1.95px 1.95px 2.6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
}

.Nichewala p {
  margin: 0;
  display: flex;
  align-items: center; 
}

.Nichewala .Share {
  display: flex;
  align-items: center;
}

.Nichewala .Share > * {
  margin-right: 10px;
}


  
  #myVideo {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
   

    .dark-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 1); 
        pointer-events: none; 
      }
    
  }
  
  @media (max-width: 768px) {
    .video-container {
      position: relative;
      height: auto;
    }
  
    #myVideo {
      width: max-content;
      width: 100%;
      height:fit-content;
      z-index: -1;
      object-fit: cover;
      
    }
    .mine{
        display: none;
    }
    .logo{
      width: 250px;
      padding-top: 10px;
    }
    .Nichewala{
      flex-direction: column;
      padding-bottom: 10px;
      font-size: 18px;

      .Location{
        padding-top: 7px;
        
      }
      .Share{
        padding-top: 10px;
      }
    }
  }
  