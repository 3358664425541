// Merch.scss

.mine {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: black;
  flex-direction: column;
  font: 1em sans-serif;
  @media only screen and (max-width: 768px) {
    flex-direction: column; /* Stack elements vertically on smaller screens */
  }
}

.heading {
  margin-top: 10%;
  font-family: cursive;
  background-image: linear-gradient(
    -225deg,
    #13ea7b 10%,
    #e9e6eb 29%,
    #6ef4ef 67%,
    #fff800 100%
    
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-size: 90px;
  @media only screen and (max-width: 768px) {
    font-size: 3rem; /* Adjust font size for smaller screens */
    margin-top: 40%;
  }
}

@keyframes textclip {
  to {
    background-position: 100% center;
  }
}

.t-box {
  margin-top: 50px;
  display: flex;
  align-items: normal;
  justify-content: space-around;
  width: 90vw;
  margin-bottom: 100px;
  flex-direction: row;
  @media only screen and (max-width: 768px) {
    padding: 20px; /* Adjust padding for better spacing on smaller screens */
    gap: 20px; /* Adjust gap for better spacing on smaller screens */
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;
    padding: 0 20px; 
  }
}

@media only screen and (max-width: 40rem) {
  .t-box {
    flex-direction: column;
    align-items: center;
    width: 80vw;

  }
}

